import { useCallback } from "react";
import { useStore } from "react-redux";
import { pasteWidget } from "legacy/actions/widgetActions";
import {
  CanvasLayout,
  PAGE_WIDGET_ID,
  WidgetTypes,
} from "legacy/constants/WidgetConstants";
import { CanvasWidgetsReduxState } from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { generateCanvasSelectionComponentId } from "legacy/utils/generators";
import { getChildrenPositionInfo } from "legacy/widgets/StackLayout/hooks";
import {
  computeClosestChildInStack,
  isStackLayout,
} from "legacy/widgets/StackLayout/utils";
import { useAppDispatch } from "store/helpers";
import { type AppState } from "store/types";

const findFirstParent = (
  initialWdgetId: string,
  widgets: CanvasWidgetsReduxState,
  matchFn: (widget: CanvasWidgetsReduxState[string]) => boolean,
) => {
  let parent = widgets[initialWdgetId];
  while (parent?.parentId) {
    if (matchFn(parent)) {
      return parent;
    }
    parent = widgets[parent.parentId];
  }
};

const findClosestCanvas = (
  targetWidgetId: string,
  widgets: CanvasWidgetsReduxState,
): CanvasWidgetsReduxState[string] | undefined => {
  const targetWidget = widgets[targetWidgetId];
  if (!targetWidget) return;

  let targetCanvas: CanvasWidgetsReduxState[string] | undefined;
  switch (targetWidget.type) {
    case WidgetTypes.CANVAS_WIDGET:
      targetCanvas = targetWidget;
      break;
    case WidgetTypes.SECTION_WIDGET:
    case WidgetTypes.CONTAINER_WIDGET:
    case WidgetTypes.FORM_WIDGET:
    case WidgetTypes.TABS_WIDGET:
      // gridwidget?
      targetCanvas = widgets[targetWidget.children?.[0] || ""];
      break;
    default:
      targetCanvas = findFirstParent(
        targetWidget.widgetId,
        widgets,
        (widget) => widget.type === WidgetTypes.CANVAS_WIDGET,
      );
      break;
  }

  return targetCanvas;
};

export function getSectionInsertionIndex({
  pasteTargetId,
  mouseY,
  widgets,
}: {
  // Note the paste target here should be the closest component to the mouse, usually the focused component but could be selected if none are focused
  pasteTargetId: string;
  mouseY: number;
  widgets: CanvasWidgetsReduxState;
}) {
  if (
    !widgets[pasteTargetId] ||
    [
      WidgetTypes.PAGE_WIDGET,
      WidgetTypes.MODAL_WIDGET,
      WidgetTypes.SLIDEOUT_WIDGET,
    ].includes(widgets[pasteTargetId]?.type as WidgetTypes)
  ) {
    return;
  }

  // look at parents until we find a section
  const sectionToCheck = findFirstParent(
    pasteTargetId,
    widgets,
    (widget) => widget.type === WidgetTypes.SECTION_WIDGET,
  );

  if (sectionToCheck) {
    const parentWidget = widgets[sectionToCheck.parentId ?? ""];
    const sectionIndex = (parentWidget.children ?? []).indexOf(
      sectionToCheck.widgetId,
    );
    if (sectionIndex == null || sectionIndex === -1) return;

    // check whether we should insert above or below the section based on where in the section the mouse is
    if (sectionToCheck.children?.[0] == null) return sectionIndex;
    const bounds = document
      .getElementById(
        generateCanvasSelectionComponentId(sectionToCheck.children?.[0] ?? ""),
      )
      ?.getBoundingClientRect();
    if (!bounds) return sectionIndex;

    const { top, bottom } = bounds;
    // if mouse position is above the middle of the section, insert above
    if (mouseY < top + (bottom - top) / 2) {
      return sectionIndex;
    }
    return sectionIndex + 1;
  }
}

export function getColumnInsertionIndex({
  pasteTargetId,
  widgets,
  mouseX,
}: {
  pasteTargetId: string;
  widgets: CanvasWidgetsReduxState;
  mouseX: number;
}) {
  if (
    !widgets[pasteTargetId] ||
    [
      WidgetTypes.SECTION_WIDGET,
      WidgetTypes.PAGE_WIDGET,
      WidgetTypes.MODAL_WIDGET,
      WidgetTypes.SLIDEOUT_WIDGET,
    ].includes(widgets[pasteTargetId].type as WidgetTypes)
  ) {
    return;
  }

  const isColumn = (widget: CanvasWidgetsReduxState[string]) =>
    widget.type === WidgetTypes.CANVAS_WIDGET &&
    widget.parentId != null &&
    widgets[widget.parentId]?.type === WidgetTypes.SECTION_WIDGET;

  // look at parents until we find a column
  const columnToCheck = findFirstParent(pasteTargetId, widgets, isColumn);

  if (columnToCheck) {
    const parentWidget = widgets[columnToCheck.parentId ?? ""];
    const columnIndex = parentWidget.children?.indexOf(columnToCheck.widgetId);
    if (columnIndex == null || columnIndex === -1) return;
    const bounds = document
      .getElementById(
        generateCanvasSelectionComponentId(columnToCheck.widgetId),
      )
      ?.getBoundingClientRect();
    if (!bounds) return columnIndex;

    const { left, right } = bounds;

    // if mouse position is to the left of the middle of the column, insert to the left
    if (mouseX < left + (right - left) / 2) {
      return columnIndex;
    }
    return columnIndex + 1;
  }
}

export type PasteInsertionIndexes = {
  sectionInsertionPosition?: number;
  columnInsertionPosition?: number;
  stackInsertionPosition?: number;
};

export const getPasteAtCursorInsertionIndexes = ({
  focusedWidget,
  pasteTargetId,
  mousePosition,
  widgets,
}: {
  focusedWidget?: string;
  pasteTargetId: string;
  mousePosition: { x: number; y: number };
  widgets: CanvasWidgetsReduxState;
}): PasteInsertionIndexes => {
  const sectionInsertionPosition = getSectionInsertionIndex({
    pasteTargetId: focusedWidget || pasteTargetId,
    mouseY: mousePosition.y,
    widgets,
  });

  const columnInsertionPosition = getColumnInsertionIndex({
    pasteTargetId: focusedWidget || pasteTargetId,
    mouseX: mousePosition.x,
    widgets,
  });

  let stackInsertionPosition: number | undefined = undefined;
  const targetCanvas = findClosestCanvas(pasteTargetId, widgets);

  if (targetCanvas && isStackLayout(targetCanvas.layout)) {
    const isVertical = targetCanvas.layout === CanvasLayout.VSTACK;
    const childRects = getChildrenPositionInfo(targetCanvas.widgetId, widgets);

    const { widgetId, widgetIndex, posIsAfterMidpoint } =
      computeClosestChildInStack({
        childRects,
        isVertical,
        x: mousePosition.x,
        y: mousePosition.y,
      });

    stackInsertionPosition =
      widgetId != null ? widgetIndex + (posIsAfterMidpoint ? 1 : 0) : 0;
  }

  return {
    sectionInsertionPosition,
    columnInsertionPosition,
    stackInsertionPosition,
  };
};

export const usePasteWidget = () => {
  const dispatch = useAppDispatch();
  const store = useStore<AppState>();

  const handlePaste = useCallback(
    ({
      // the initial paste target id is the widget that we think we want to paste into,
      // ex: right clicked on a widget to paste into it. But it may be different depending
      // on what's been copied, as there are different rules for pasting depending on
      // what's being pasted
      pasteTargetId,
      pasteAtCursor = false,
      forcePasteIntoContainer = false,
      mousePosition,
      pasteAtCursorInsertionIndexes,
    }: {
      pasteTargetId?: string;
      pasteAtCursor?: boolean;
      forcePasteIntoContainer?: boolean;
      mousePosition?: { x: number; y: number };
      pasteAtCursorInsertionIndexes?: PasteInsertionIndexes;
    }) => {
      const state = store.getState();
      const selectedWidgetIds =
        state.legacy.ui.widgetDragResize.selectedWidgets;

      // Only paste if there's a selected widget
      if (!selectedWidgetIds.length) return;
      const pasteTargetIdDefaulted =
        pasteTargetId ?? selectedWidgetIds[0] ?? PAGE_WIDGET_ID;

      // If we're not pasting exactly one widget, then just do a regular paste
      // and don't worry about mouse position
      // TODO: Multi-paste at mouse should work for stacks as we can easiy support it.
      if (selectedWidgetIds?.length !== 1 || !pasteAtCursor) {
        dispatch(
          pasteWidget({
            pasteTargetId: pasteTargetIdDefaulted,
            forcePasteIntoContainer,
          }),
        );

        return;
      }

      // Determine insertion indexes for each type of paste (section, column, stack, grid):
      // Because we can't access localStorage of the main container to determine what is being pasted,
      // we figure out the insertion indexes for each type of paste (section, column, stack)
      // const widgets = getWidgets(state);
      const defaultMouseCoords = {
        x: mousePosition?.x ?? 0,
        y: mousePosition?.y ?? 0,
      };

      return dispatch(
        pasteWidget({
          pasteTargetId: pasteTargetIdDefaulted,
          pasteAtCursor,
          forcePasteIntoContainer,
          mousePosition: defaultMouseCoords,
          ...pasteAtCursorInsertionIndexes,
        }),
      );
    },
    [dispatch, store],
  );

  return handlePaste;
};
