import { CloseOutlined } from "@ant-design/icons";
import { Dimension, Padding } from "@superblocksteam/shared";
import { Button, Modal } from "antd";
import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Layers } from "legacy/constants/Layers";
import {
  ModalSize,
  MODAL_TARGET_ID,
  MODAL_PERIMETER_GAP,
  MODAL_BORDER_RADIUS,
} from "legacy/constants/WidgetConstants";
import { useStickySectionOnParentScroll } from "legacy/hooks/useStickySectionOnParentScroll";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { getSelectedWidgetsIds } from "legacy/selectors/sagaSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import { WidgetPropsRuntime } from "../BaseWidget";
import {
  ComputeStyleProps,
  useComputeWidgetStyles,
} from "../base/generateWidgetStyles";
import { ZERO_PADDING } from "../base/sizing";
import { useFillParentHeightInfo } from "../base/sizing/dynamicLayoutHooks";

const MODAL_CLASS_NAME = "modal-widget-component";

const ModalScrollBackground = createGlobalStyle`
  .ant-modal-wrap {
    overflow-x: auto;
    overflow-y: auto;
  }
  .${MODAL_CLASS_NAME} {
    &&& .ant-modal-content {
      // This background property is important as it overrides the AntD default and ensures our theme background is visible
      background: none;
      background-color: none;
      box-shadow: none;
    }
    .ant-modal-body {
      background: none;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      border-radius: ${MODAL_BORDER_RADIUS}px;
    }
  }
`;

type ModalComponentProps = {
  appMode: APP_MODE;
  widgetId: string;
  isOpen: boolean;
  isVisible: boolean;
  isClosing: boolean;
  innerWidgets: WidgetPropsRuntime[];
  onClose: (e: any) => void;
  afterVisibleChange: (visible: boolean) => void;
  children: ReactNode;
  className?: string;
  canOutsideClickClose: boolean;
  hasBackdrop: boolean;
  widthPreset?: ModalSize;
  heightPreset?: ModalSize;
  modalStyle?: React.CSSProperties;
  internalWidth?: Dimension<"px">;
  styleProps: ComputeStyleProps;
};

const getParent = () => {
  return (
    (document.querySelector(
      `[data-superblocks='${MODAL_TARGET_ID}']`,
    ) as HTMLElement) || document.body
  );
};

const CloseButtonStyled = styled(Button)`
  position: absolute;
  z-index: ${Layers.closeButton};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    /* make the image a block element so that it observes vertical alignment rules better */
    display: block;
  }
`;

const CLOSE_BUTTON_MIN_OFFSET = 4;

const CloseButton = (props: {
  onClose: (e: any) => void;
  padding: Padding;
}) => {
  const { onClose, padding } = props;
  const positionStyles = useMemo(() => {
    const top = Math.max(padding.top?.value ?? 0, CLOSE_BUTTON_MIN_OFFSET);
    const right = Math.max(padding.right?.value ?? 0, CLOSE_BUTTON_MIN_OFFSET);
    return {
      top,
      right,
    };
  }, [padding]);

  return (
    <CloseButtonStyled
      type="text"
      aria-label="Close"
      data-test="modal-close"
      onClick={onClose}
      style={positionStyles}
    >
      <CloseOutlined className={CLASS_NAMES.CLOSE_ICON} />
    </CloseButtonStyled>
  );
};

const ModalComponent = (props: ModalComponentProps) => {
  const afterVisibleChange = useRef(props.afterVisibleChange);
  const isModalOpen = useMemo(() => {
    afterVisibleChange.current = props.afterVisibleChange;
    if (props.isClosing) {
      return props.isOpen;
    } else {
      return props.isVisible;
    }
  }, [
    props.afterVisibleChange,
    props.isClosing,
    props.isOpen,
    props.isVisible,
  ]);

  const modalPadding = props.styleProps?.padding ?? ZERO_PADDING;

  useEffect(() => {
    afterVisibleChange.current(isModalOpen);
  }, [isModalOpen]);

  const modalWidthPx = useMemo(() => {
    if (props.internalWidth?.value) {
      return props.internalWidth.value;
    }
    const fallbackWidthPx = props.innerWidgets.reduce((acc, widget) => {
      const widgetWidth = Dimension.toPx(
        widget.width,
        widget.parentColumnSpace,
      ).value;
      return Math.max(widgetWidth, acc);
    }, 0);
    // Child widths incorporate padding if set. To ensure the modal body is the correct width, we need to add the padding back.
    return Dimension.add(Padding.x(modalPadding), Dimension.px(fallbackWidthPx))
      .value;
  }, [props.innerWidgets, modalPadding, props.internalWidth]);

  const [, fpMinHeight, fpMaxHeight] = useFillParentHeightInfo(props.widgetId);
  useStickySectionOnParentScroll(`modal-component-${props.widgetId}`);

  const selectedWidgetIds = useAppSelector((state: AppState) =>
    getSelectedWidgetsIds(state),
  );
  const isSelected = selectedWidgetIds.includes(props.widgetId);
  const modalStyle = useComputeWidgetStyles(props.styleProps ?? {});

  return (
    <>
      <ModalScrollBackground />
      <Modal
        width={modalWidthPx}
        open={isModalOpen}
        footer={null}
        onCancel={(e: any) => {
          props.onClose(e);
        }}
        maskClosable={props.canOutsideClickClose}
        maskStyle={
          !props.hasBackdrop
            ? { backgroundColor: "transparent", overflow: "auto" }
            : { overflow: "auto" }
        }
        keyboard={props.canOutsideClickClose}
        getContainer={getParent}
        style={{
          minWidth: modalWidthPx,
          maxWidth: "unset" /* override antd's max-width */,
          maxHeight:
            fpMaxHeight != null
              ? `min(${fpMaxHeight}px, 100% - ${MODAL_PERIMETER_GAP * 2}px)`
              : `calc(100% - ${MODAL_PERIMETER_GAP * 2}px)`,
          height: fpMinHeight != null ? "100%" : undefined,
          minHeight: fpMinHeight ?? 110,
        }}
        bodyStyle={{
          height: "100%",
          position: "relative",
          ...modalStyle,
        }}
        zIndex={Layers.modal}
        closable={false /* use our own close button */}
        data-test="modal-component"
        wrapClassName={`${CLASS_NAMES.MODAL} ${CLASS_NAMES.STYLED_SCROLLBAR}`}
        className={MODAL_CLASS_NAME}
        wrapProps={{
          id: `modal-component-${props.widgetId}`,
          "data-superblocks": `modal-component-${props.widgetId}`,
        }}
      >
        {props.appMode === APP_MODE.EDIT && isSelected && (
          <PaddingOverlay widgetId={props.widgetId} padding={modalPadding} />
        )}
        <CloseButton onClose={props.onClose} padding={modalPadding} />
        {props.children}
      </Modal>
    </>
  );
};

export default ModalComponent;
