type RGB = [number, number, number];
type RGBA = [number, number, number, number];

export const rgba2rgb = (color: RGBA, background: RGB) => {
  const alpha = color[3];
  return [
    Math.floor((1 - alpha) * background[0] + alpha * color[0] + 0.5),
    Math.floor((1 - alpha) * background[1] + alpha * color[1] + 0.5),
    Math.floor((1 - alpha) * background[2] + alpha * color[2] + 0.5),
  ];
};
