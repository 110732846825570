import { Classes, Popover } from "@blueprintjs/core";
import { Dimension } from "@superblocksteam/shared/src/types";
import React from "react";
import DynamicSVG from "components/ui/DynamicSVG";
import { styleAsClass } from "styles/styleAsClass";
import ButtonComponent, {
  StaticTypographyButtonProps,
} from "../ButtonWidget/ButtonComponent";
import { ButtonComponentProps } from "../ButtonWidget/types";
import { MenuList, MenuListProps } from "./MenuList";

// Applies to the popover element
const PopoverElementWrapper = styleAsClass`
 &&&& .${Classes.POPOVER_CONTENT} {
    // By default this class has overflow: hidden;
    // which prevents 2nd-level sub-menus from showing
    overflow: visible;

    padding: 0;
    border-radius: 4px;
  }
`;

// Applies to popover target (the menu button)
const PopoverTargetWrapper = styleAsClass`
  &&&&.${Classes.POPOVER_TARGET} > * {
    height: 100%; // allow button to be expanded
  }
`;

export interface MenuComponentProps {
  items: MenuListProps["items"];
  label?: string;
  isDisabled?: boolean;

  // Button props
  button: Omit<
    ButtonComponentProps,
    "onClick" | "text" | "isLoading" | "icon" | "width" | "maxWidth"
  > & {
    icon?: string;
    width: Dimension;
    maxWidth?: Dimension;
  };
}

export default function MenuComponentWrapper(props: MenuComponentProps) {
  // TODO: Either calculate this when the menu opens,
  // or store the results until data in bindings, or children change
  const menu =
    props.items.length > 0 ? <MenuList items={props.items} /> : undefined;

  return (
    <Popover
      content={menu}
      placement="bottom"
      fill={true}
      popoverClassName={PopoverElementWrapper}
      className={PopoverTargetWrapper}
    >
      <ButtonComponent
        text={props.label}
        disabled={props.isDisabled}
        isLoading={false}
        buttonStyle={props.button.buttonStyle}
        width={props.button.width.mode === "fitContent" ? "auto" : "100%"}
        textColor={props.button.textColor}
        backgroundColor={props.button.backgroundColor}
        maxWidth={props.button.maxWidth?.value}
        icon={
          props.button.icon ? (
            <DynamicSVG size={18} iconName={props.button.icon} />
          ) : (
            <></>
          )
        }
        iconPosition={props.button.iconPosition ?? "LEFT"}
        textProps={StaticTypographyButtonProps}
      />
    </Popover>
  );
}
