import { Padding } from "@superblocksteam/shared";
import { CSSProperties } from "react";
import { css } from "styled-components";

export const generatePaddingCss = (padding?: Padding) => css`
  padding-left: ${padding?.left?.value ?? 0}${padding?.left?.mode ?? "px"};
  padding-right: ${padding?.right?.value ?? 0}${padding?.right?.mode ?? "px"};
  padding-top: ${padding?.top?.value ?? 0}${padding?.top?.mode ?? "px"};
  padding-bottom: ${padding?.bottom?.value ?? 0}${padding?.bottom?.mode ?? "px"};
`;

export const generatePaddingStyleObject = (
  padding?: Padding,
): CSSProperties => ({
  paddingLeft: `${padding?.left?.value ?? 0}${padding?.left?.mode ?? "px"}`,
  paddingRight: `${padding?.right?.value ?? 0}${padding?.right?.mode ?? "px"}`,
  paddingTop: `${padding?.top?.value ?? 0}${padding?.top?.mode ?? "px"}`,
  paddingBottom: `${padding?.bottom?.value ?? 0}${
    padding?.bottom?.mode ?? "px"
  }`,
});
