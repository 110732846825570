import React, {
  RefObject,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import tinycolor from "tinycolor2";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { generateClassName, getCanvasClassName } from "legacy/utils/generators";
import { ScrollContainer } from "../Shared/ScrollContainer";
import { TabsComponentProps } from "./types";
import { computeTabHeaderHeightPx } from "./utils";

const TabsContainerWrapper = styled.div<{
  ref: RefObject<HTMLDivElement>;
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ChildrenWrapper = styled.div<{ shouldShowTabs: boolean }>`
  position: relative;

  /* 
    Removes 1px padding from Container elements to support full-width children
    does not remove padding from Canvas elements
    -1 margin means +2 width/height
    
    We then add an extra 2px to height to account for the 1px border border scrolling when padding is zero
    This is admittedly a hack - Mark
  */
  width: calc(100% + 2px);
  margin: -1px;
`;

const TabsContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: hidden;
  && {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

type TabProps = {
  selected?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const StyledTab = styled.div`
  width: 100%;
`;

const StyledText = styled.div<TabProps>`
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  ${({ selected }) =>
    selected
      ? css`
          &::after {
            content: "";
            position: absolute;
            height: 2px;
            width: 60%;
            left: 20%;
            bottom: -1px;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
          }
        `
      : ""}
`;

const TabsComponent = (props: TabsComponentProps) => {
  const tabs = props.tabs || [];
  const { onTabChange } = props;
  const tabContainerRef: RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!props.shouldScrollContents) {
      tabContainerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [props.shouldScrollContents]);

  const tabClickHandler = useCallback(
    (widgetId: string) => {
      return (event: React.MouseEvent<HTMLDivElement>) => {
        onTabChange(widgetId);
        event.stopPropagation();
      };
    },
    [onTabChange],
  );

  const generatedTheme = useSelector(selectGeneratedTheme);
  const useBorderOutline =
    props.shouldUseBorderOutline?.(generatedTheme) ?? false;

  const { tabsHeight, childrenWrapperStyle } = useMemo(() => {
    const tabsHeight = computeTabHeaderHeightPx(
      props.headerLineHeightPx,
      props.shouldShowTabs,
    );
    const childrenWrapperStyle = {
      height: `calc(
    100% + 4px -
      ${props.shouldShowTabs ? tabsHeight : 0}px
  )`,
    };
    return {
      tabsHeight,
      childrenWrapperStyle,
    };
  }, [props.headerLineHeightPx, props.shouldShowTabs]);

  const [wrapperStyle, wrapperClassNames] = useMemo(() => {
    const classNames = useBorderOutline
      ? [
          CLASS_NAMES.DEFAULT_CONTAINER,
          CLASS_NAMES.CONTAINER_BORDER_OUTLINE,
        ].join(" ")
      : CLASS_NAMES.DEFAULT_CONTAINER;

    const style: React.CSSProperties = {};
    if (useBorderOutline) {
      style.border = "none";
    }
    return [style, classNames];
  }, [useBorderOutline]);

  const [activeTabTextStyle, tabTextStyle] = useMemo(() => {
    const activeStyle = {
      ...props.headerProps.style,
      height: `${tabsHeight}px`,
    };

    return [
      activeStyle,
      props.headerProps.style?.color
        ? {
            ...activeStyle,
            color: tinycolor
              .mix(
                props.headerProps.style.color,
                generatedTheme.colors.neutral,
                40,
              )
              .toRgbString(),
          }
        : activeStyle,
    ];
  }, [props.headerProps.style, tabsHeight, generatedTheme]);

  const headerClassName = getTextCssClassFromVariant(
    props.headerProps.textStyleVariant,
  );

  return (
    <TabsContainerWrapper
      ref={tabContainerRef}
      className={wrapperClassNames}
      style={wrapperStyle}
    >
      {props.shouldShowTabs ? (
        <TabsContainer
          role="tablist"
          style={{
            height: `${tabsHeight}px`,
            flex: `0 0 ${tabsHeight}px`,
          }}
        >
          {tabs &&
            tabs.map((tab, index) => (
              <StyledText
                data-test={`tab-${tab.label}`}
                role="tab"
                onClick={tabClickHandler(tab.widgetId)}
                selected={props.selectedTabWidgetId === tab.widgetId}
                aria-selected={props.selectedTabWidgetId === tab.widgetId}
                key={index}
                id={props.selectedTabWidgetId}
                tabIndex={props.selectedTabWidgetId === tab.widgetId ? 0 : -1}
                style={
                  props.selectedTabWidgetId === tab.widgetId
                    ? activeTabTextStyle
                    : tabTextStyle
                }
                className={`${CLASS_NAMES.TAB} ${headerClassName ?? ""} ${
                  props.selectedTabWidgetId === tab.widgetId
                    ? CLASS_NAMES.ACTIVE_MODIFIER
                    : ""
                }`}
              >
                {tab.label}
              </StyledText>
            ))}
          <StyledTab
            className={CLASS_NAMES.TAB}
            style={{
              height: `${tabsHeight}px`,
            }}
          />
        </TabsContainer>
      ) : undefined}
      <ChildrenWrapper
        shouldShowTabs={props.shouldShowTabs}
        style={childrenWrapperStyle}
      >
        <ScrollContainer
          scroll={props.shouldScrollContents ?? false}
          className={`${
            props.shouldScrollContents ? getCanvasClassName() : ""
          } ${generateClassName(props.widgetId)} ${CLASS_NAMES.STYLED_SCROLLBAR}
      `}
          role="tabpanel"
          aria-labelledby={props.selectedTabWidgetId}
          innerStyle={{ height: "calc(100% + 2px)" }}
        >
          {props.children}
        </ScrollContainer>
      </ChildrenWrapper>
    </TabsContainerWrapper>
  );
};

export default TabsComponent;
