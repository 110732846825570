// Scope Blueprint components to avoid any confusion with our own types
import {
  Classes,
  Menu as BlueprintMenu,
  MenuDivider as BlueprintMenuDivider,
  MenuItem as BlueprintMenuItem,
  MenuItemProps as BlueprintMenuItemProps,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as ChevronRightIcon } from "assets/icons/common/chevron-right.svg";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { ComponentMenuItem, MenuItemType } from "./types";

const MenuStyles = styleAsClass`
    && .${Classes.MENU}, &&.${Classes.MENU} { // Both itself and descendants 
      padding: 8px;
    }
    && .${Classes.MENU_ITEM} {
      border-radius: 4px;
      font-size: 12px;
      padding: 8px 10px;
    }

    &&&[data-theme-mode="LIGHT"] .${Classes.MENU_ITEM} {
      color: ${colors.GREY_700};
    }
    &&&[data-theme-mode="DARK"] .${Classes.MENU_ITEM} {
      color: ${colors.WHITE};
    }

    &&&&&[data-theme-mode="LIGHT"] .${Classes.MENU_ITEM}:hover {
      background-color: #F3F5F7;
      color: ${colors.GREY_700};
    }

    &&&&&[data-theme-mode="DARK"] .${Classes.MENU_ITEM}:hover {
      background-color: #31384A;
      color: ${colors.WHITE};
    }

    && .${Classes.MENU_SUBMENU} .${Classes.POPOVER} {
      padding: 0;
    }

    && .${Classes.MENU_SUBMENU_ICON} {
      // Hack to customize the submenu's caret icon.
      // See how we use labelElement on each MenuItem
      display: none;
    }

    && .${Classes.MENU_ITEM} > * {
      // Hack to customize the submenu's caret icon.
      // See how we use labelElement on each MenuItem
      margin-right: 0;
    }

    && .${Classes.MENU_ITEM_LABEL} svg path {
      stroke: ${colors.GREY_700};
    }
  }
`;

export interface MenuListProps {
  items: ComponentMenuItem[];
}

function renderMenuItem(item: ComponentMenuItem, index: number) {
  if (item.type === MenuItemType.Divider) {
    return <BlueprintMenuDivider key={index} />;
  }

  const children = item.children
    ? item.children.map((child, i) => renderMenuItem(child, i))
    : undefined;

  const menuItemProps: BlueprintMenuItemProps = {
    text: item.label,
  };

  if ("href" in item) {
    menuItemProps.href = item.href;
  }

  return (
    <BlueprintMenuItem
      {...menuItemProps}
      key={index}
      labelElement={children ? <ChevronRightIcon /> : undefined}
    >
      {children}
    </BlueprintMenuItem>
  );
}

export function MenuList(props: MenuListProps) {
  const theme = useSelector(selectGeneratedTheme);
  const children = props.items.map((item, i) => renderMenuItem(item, i));
  return (
    <BlueprintMenu className={MenuStyles} data-theme-mode={theme.mode}>
      {children}
    </BlueprintMenu>
  );
}
