import { Dimension, Padding } from "@superblocksteam/shared";
import React, { memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import DynamicSVG from "components/ui/DynamicSVG";
import { selectWidgets } from "legacy/actions/widgetActions";
import { GridDefaults, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { APP_MODE } from "legacy/reducers/types";
import { selectGeneratedThemeTypographies } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes";
import { BUTTON_PADDING } from "legacy/themes/constants";
import { useAppSelector } from "store/helpers";
import { getComponentDimensions } from "utils/size";
import { Button, FIT_CONTENT_PADDING } from "../Shared/Button";
import { isFitContent } from "../base/sizing";
import {
  getApplicableMaxWidth,
  getApplicableMinWidth,
} from "../base/sizing/canvasWidthUtil";
import { type TypographyProps, useTypographyStyling } from "../typographyHooks";
import {
  getFontSizeInPxFromTextStyle,
  getIconSizeBasedOnFontSize,
  getLineHeightInPxFromTextStyle,
} from "../typographyUtils";
import { DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT } from "./constants";
import type {
  ButtonComponentProps,
  ButtonComponentWithLayoutManagedProps,
} from "./types";

export const StaticTypographyButtonProps: TypographyProps = {
  textStyleVariant: DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
};

const ButtonComponent = memo((props: ButtonComponentProps) => {
  const dispatch = useDispatch();
  const { onClick, appMode, widgetId, textProps } = props;

  const onClickLocal = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (appMode === APP_MODE.EDIT && widgetId) {
        dispatch(selectWidgets([widgetId], false));
      }

      if (onClick) {
        onClick(event);
      }
    },
    [onClick, appMode, widgetId, dispatch],
  );

  return (
    <div
      onClick={onClickLocal}
      data-dd-action-name={props.ddogActionName ?? "button"}
    >
      <Button
        textColor={props.textColor}
        backgroundColor={props.backgroundColor}
        buttonStyle={props.buttonStyle}
        isLoading={props.isLoading}
        text={props.text}
        disabled={props.disabled}
        useDynamicContrast={props.buttonStyle === "PRIMARY_BUTTON"}
        icon={props.icon}
        iconPosition={props.iconPosition}
        width={props.width}
        height={props.height}
        maxWidth={props.maxWidth}
        minWidth={props.minWidth}
        textProps={textProps}
        textAlignment={props.textAlignment}
        border={props.border}
        borderRadius={props.borderRadius}
      />
    </div>
  );
});

ButtonComponent.displayName = "Button";

export default ButtonComponent;

const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);
export const ButtonComponentWithLayoutManaged = (
  props: ButtonComponentWithLayoutManagedProps,
) => {
  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);

  // Doesn't include color overwrites on purpose - color is handled in the button primitive with contrast support
  const textProps = useTypographyStyling({
    textStyle: props.textProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
    skipTextStyleColorDefaultOverwrite: true,
  });

  const { componentWidth } = getComponentDimensions(props);
  const isAutoWidth = isFitContent(props.width.mode);
  const isAutoHeight = isFitContent(props.height.mode);

  let maxWidth = (getApplicableMaxWidth(props) ?? props.internalMaxWidth)
    ?.value;
  if (maxWidth) {
    maxWidth = maxWidth - 2 * WIDGET_PADDING;
  }
  let minWidth = getApplicableMinWidth(props)?.value;
  if (minWidth) {
    minWidth = minWidth - 2 * WIDGET_PADDING;
  }

  const iconSize = useMemo(() => {
    return textProps.textStyleVariant
      ? getIconSizeBasedOnFontSize(
          getFontSizeInPxFromTextStyle({
            nestedProps: props.textProps?.textStyle,
            typographies: themeTypographies,
            textStyleVariant: textProps.textStyleVariant,
            defaultTextStyleVariant: DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
          }),
        )
      : clamp(
          !isAutoWidth
            ? componentWidth -
                Padding.x(BUTTON_PADDING).value -
                WIDGET_PADDING * 2
            : 18,
          8,
          18,
        );
  }, [
    textProps.textStyleVariant,
    props.textProps?.textStyle,
    isAutoWidth,
    componentWidth,
    themeTypographies,
  ]);

  const icon = useMemo(() => {
    return props.icon ? (
      <DynamicSVG size={iconSize} iconName={props.icon} />
    ) : (
      <></>
    );
  }, [iconSize, props.icon]);

  return (
    <ButtonComponent
      buttonStyle={props.buttonStyle}
      textColor={props.textColor}
      backgroundColor={props.backgroundColor}
      key={props.widgetId}
      text={props.text}
      textAlignment={props.textAlignment}
      disabled={props.isDisabled}
      onClick={!props.isDisabled ? props.onClick : undefined}
      isLoading={props.isLoading}
      width={isAutoWidth ? "auto" : "100%"}
      height={isAutoHeight ? "auto" : "100%"}
      maxWidth={maxWidth}
      minWidth={minWidth}
      appMode={props.appMode}
      widgetId={props.widgetId}
      iconPosition={props.iconPosition ?? "LEFT"}
      ddogActionName="button-widget"
      textProps={textProps}
      icon={icon}
      border={props.border}
      borderRadius={props.borderRadius}
    />
  );
};

export const estimateInitialButtonWidgetHeightGU = (
  theme?: GeneratedTheme,
): number | undefined => {
  if (!theme?.typographies) {
    return undefined;
  }

  const textHeight = getLineHeightInPxFromTextStyle({
    textStyleVariant: DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
    nestedProps: undefined,
    typographies: theme.typographies,
    defaultTextStyleVariant: DEFAULT_BUTTON_WIDGET_TEXT_STYLE_VARIANT,
  });

  const totalHeightPx = textHeight + 2 * FIT_CONTENT_PADDING;

  return Dimension.toGridUnit(
    Dimension.px(totalHeightPx),
    GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
  ).roundUp().value;
};
