import { InputGroup } from "@blueprintjs/core";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useDebounce } from "hooks/ui";
import { TextStyleWithVariant } from "legacy/themes";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { DEFAULT_INPUT_TEXT_STYLE_VARIANT } from "legacy/themes/typographyConstants";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";

interface StyledInputGroupProps {
  $compact?: boolean;
  $noMargin?: boolean;
  $width?: string;
  $flexGrow?: string;
}

const StyledInputGroup = styled(InputGroup)<StyledInputGroupProps>`
  &.bp5-input-group {
    line-height: unset !important;
  }
  &&& input {
    height: 100%;
    padding-left: ${({ $compact }) => ($compact ? "28px" : "38px")};
  }

  &&& span {
    margin: ${({ $compact }) => ($compact ? "3px" : "9px")};
  }

  &&& svg {
    opacity: 1;
  }
  margin: ${({ $noMargin }) => ($noMargin ? "0" : "12px 16px")};
  width: ${({ $width }) => $width ?? "204px"};
  min-width: 150px;
  height: ${({ $compact }) => ($compact ? "24px" : "36px")};
  ${({ $flexGrow }) => $flexGrow && `flex-grow: ${$flexGrow}`};
`;

interface SearchComponentProps {
  onSearch: (value: any) => void;
  placeholder: string;
  value: string;
  compact?: boolean;
  noMargin?: boolean;
  width?: string;
  flexGrow?: string;
  dataTest?: string;
  searchInputStyle?: TextStyleWithVariant;
}

const SearchComponent = (props: SearchComponentProps) => {
  const debouncedSearch = useDebounce(props.onSearch, 400);
  const [localValue, setLocalValue] = React.useState(props.value);

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  const handleSearch = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
      const search = event.target.value;
      setLocalValue(search);
      debouncedSearch?.(search);
    },
    [debouncedSearch],
  );

  const inputProps = useTypographyStyling({
    textStyle: props.searchInputStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_TEXT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps.textStyleVariant,
    type: "input",
    isLoading: false,
    isDisabled: false,
  });

  return (
    <StyledInputGroup
      leftIcon="search"
      type="search"
      role="search"
      onChange={handleSearch}
      placeholder={props.placeholder}
      value={localValue ?? ""}
      $compact={props.compact}
      $noMargin={props.noMargin}
      $width={props.width}
      $flexGrow={props.flexGrow}
      data-test={props.dataTest}
      className={`${inputClass ?? ""} ${CLASS_NAMES.INPUT}`}
      style={inputProps.style}
      autoFocus={false}
    />
  );
};

export default SearchComponent;
