import React from "react";
import { Layers } from "legacy/constants/Layers";

export const ComponentBorder = ({
  borderStyle,
  className,
}: {
  borderStyle: React.CSSProperties;
  className?: string;
}) => {
  return (
    <div
      className={className}
      style={{
        background: "transparent",
        position: "absolute",
        height: "100%",
        width: "100%",
        borderRadius: "inherit",
        pointerEvents: "none",
        zIndex: Layers.componentBorder,
        top: "0px",
        left: "0px",
        ...borderStyle,
      }}
    ></div>
  );
};
