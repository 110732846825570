import { PerCornerBorderRadius, PerSideBorder } from "@superblocksteam/shared";
import { CSSProperties } from "react";

export const generateBorderStyleObject = ({
  border,
  fallbackBorderColor,
  borderRadius,
}: {
  border?: PerSideBorder;
  fallbackBorderColor?: string;
  borderRadius?: PerCornerBorderRadius;
}): CSSProperties => {
  const styles: CSSProperties = {};
  if (!border && fallbackBorderColor) {
    styles.borderColor = fallbackBorderColor;
  }
  if (!border && !borderRadius) {
    return styles;
  }
  if (border?.left) {
    styles.borderLeftColor = border.left.color ?? fallbackBorderColor;
    styles.borderLeftWidth = border.left.width
      ? `${border.left.width.value ?? 0}${border.left.width.mode ?? "px"}`
      : "0px";
    styles.borderLeftStyle = border.left.style ?? "solid";
  }
  if (border?.right) {
    styles.borderRightColor = border.right.color ?? fallbackBorderColor;
    styles.borderRightWidth = border.right.width
      ? `${border.right.width.value ?? 0}${border.right.width.mode ?? "px"}`
      : "0px";
    styles.borderRightStyle = border.right.style ?? "solid";
  }
  if (border?.top) {
    styles.borderTopColor = border.top.color ?? fallbackBorderColor;
    styles.borderTopWidth = border.top.width
      ? `${border.top.width.value ?? 0}${border.top.width.mode ?? "px"}`
      : "0px";
    styles.borderTopStyle = border.top.style ?? "solid";
  }
  if (border?.bottom) {
    styles.borderBottomColor = border.bottom.color ?? fallbackBorderColor;
    styles.borderBottomWidth = border.bottom.width
      ? `${border.bottom.width.value ?? 0}${border.bottom.width.mode ?? "px"}`
      : "0px";
    styles.borderBottomStyle = border.bottom.style ?? "solid";
  }
  return {
    ...styles,
    ...generateBorderRadiusStyleObject({ borderRadius }),
  };
};

export const generateBorderRadiusStyleObject = ({
  borderRadius,
}: {
  borderRadius?: PerCornerBorderRadius;
}): CSSProperties => {
  const styles: CSSProperties = {};
  // border radius
  if (borderRadius?.topLeft) {
    styles.borderTopLeftRadius = `${borderRadius.topLeft.value ?? 0}${
      borderRadius.topLeft.mode
    }`;
  }
  if (borderRadius?.topRight) {
    styles.borderTopRightRadius = `${borderRadius.topRight.value ?? 0}${
      borderRadius.topRight.mode
    }`;
  }
  if (borderRadius?.bottomLeft) {
    styles.borderBottomLeftRadius = `${borderRadius.bottomLeft.value ?? 0}${
      borderRadius.bottomLeft.mode
    }`;
  }
  if (borderRadius?.bottomRight) {
    styles.borderBottomRightRadius = `${borderRadius.bottomRight.value ?? 0}${
      borderRadius.bottomRight.mode
    }`;
  }
  return styles;
};
