import React from "react";
import { TextStyleWithVariant } from "legacy/themes";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import InputComponent, { InputComponentProps } from "./InputComponent";
import {
  DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
  DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
} from "./InputWidgetConstants";

type WithLayoutManagedProps = {
  labelProps?: {
    textStyle: TextStyleWithVariant;
  };
  inputProps?: {
    textStyle: TextStyleWithVariant;
  };
} & InputComponentProps;

export const InputComponentWithLayoutManaged = (
  props: WithLayoutManagedProps,
) => {
  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_LABEL_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const inputProps = useTypographyStyling({
    textStyle: props.inputProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_INPUT_WIDGET_INPUT_STYLE_VARIANT,
    applyClassNameStylesToStyle: true,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.disabled,
    type: "label",
  });

  const inputClass = useStyleClassNames({
    textStyleVariant: inputProps?.textStyleVariant, // Has fallback in place
    isLoading: props.isLoading,
    isDisabled: props.isDisabled,
    type: "input",
  });

  return (
    <InputComponent
      {...props}
      inputClassName={inputClass}
      inputStyleOverride={inputProps?.style}
      labelClassName={labelClass}
      labelStyleOverride={labelProps?.style}
    />
  );
};
