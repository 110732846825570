import {
  ComponentMenuItem,
  ManualMenuItem,
  MenuChildrenType,
  MenuItemType,
} from "./types";

export function transformItem(item: ManualMenuItem): ComponentMenuItem {
  if (item.type === MenuItemType.Divider) {
    return item;
  }

  if (item.childrenType === MenuChildrenType.Manual) {
    const children = item.manualChildren
      ? item.manualChildren.map((child) => transformItem(child))
      : undefined;

    switch (item.type) {
      case MenuItemType.Button: {
        return {
          type: item.type,
          label: item.label,
          openOn: item.openOn,
          children,
        };
      }
      case MenuItemType.Link: {
        return {
          type: item.type,
          label: item.label,
          href: item.href,
          targetBlank: item.targetBlank,
          children,
        };
      }
      default: {
        throw new Error(`Invalid menu item type for ${item}`);
      }
    }
  } else {
    throw new Error(`Non-manual children types are implemented yet`);
  }
}
