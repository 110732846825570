import { Padding, ThemeMode } from "@superblocksteam/shared";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import paddingSVG from "assets/images/canvas/canvasPadding.svg";
import paddingDarkSVG from "assets/images/canvas/canvasPaddingDark.svg";
import { getPaddingIsFocused } from "legacy/selectors/propertyPaneSelectors";
import { getIsWidgetSelected } from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { GeneratedTheme } from "legacy/themes/types";
import { useAppSelector } from "store/helpers";
import { StyledBorder, generateMaskSize } from "./StyledBorder";
import type { AppState } from "store/types";

const StyledPaddingBorder = styled(StyledBorder)`
  transition: opacity 0.1s;
  &[data-dark-theme="true"] {
    background: url(${paddingDarkSVG});
  }
  &[data-dark-theme="false"] {
    background: url(${paddingSVG});
  }
`;

type Props = {
  widgetId: string;
  padding?: Padding;
  parentId?: string;
  themeMode?: GeneratedTheme["mode"];
};

// This is to show a padding overlay that displays the padding of the widget when requested.
// We show this padding on a widget when the user is resizing or dragging widgets into it.
// We display the overlay on a 2d canvas, so we need to calculate the position of the overlay
const PaddingOverlayInternal = (props: Props) => {
  const { widgetId } = props;
  const widgetSelected = useAppSelector((state) =>
    getIsWidgetSelected(state, widgetId),
  );
  const propertyIsHovered = useAppSelector(getPaddingIsFocused);
  const isDropTarget = useAppSelector(
    (state: AppState) =>
      state.legacy.ui.widgetDragResize.currentDropTarget === widgetId,
  );

  const show = widgetSelected || isDropTarget;

  // width 100% does not necessarily match up with our scrollable parent's width. We have to do this janky check.
  // We want the canvas to match the content height, but also match the content PARENT's width
  const getParentScrollbarWidth = useCallback(() => {
    const getContainerId = (id: string) => `container-inner-container-${id}`;
    const parentScrollable =
      document.getElementById(
        getContainerId(`${widgetId}--container-wrapper`),
      ) ?? props.parentId != null
        ? document.getElementById(getContainerId(props.parentId ?? ""))
        : null;
    return parentScrollable
      ? parentScrollable.offsetWidth - parentScrollable.clientWidth
      : 0;
  }, [widgetId, props.parentId]);

  // Should be ok to run this on each render since there should be low chance of parent scrollbar appearing/disappearing
  // without triggering a render or mount of this component
  const parentScrollbarWidth = getParentScrollbarWidth();
  const style = useMemo(() => {
    return {
      width: `calc(100% + ${parentScrollbarWidth}px)`,
      maskImage: generateMaskSize(props.padding),
      // alpha based on whether the property is hovered or not
      opacity: propertyIsHovered ? 1 : 0.4,
    };
  }, [parentScrollbarWidth, propertyIsHovered, props.padding]);

  return props.padding && show ? (
    <StyledPaddingBorder
      style={style}
      data-dark-theme={props.themeMode === ThemeMode.DARK}
    />
  ) : (
    <></>
  );
};

const PaddingOverlay = (props: Omit<Props, "themeMode">) => {
  const theme = useAppSelector(selectGeneratedTheme);
  return (
    <PaddingOverlayInternal
      themeMode={theme.mode}
      padding={props.padding ?? theme.padding}
      widgetId={props.widgetId}
      parentId={props.parentId}
    />
  );
};

export default PaddingOverlay;
